html {
  box-sizing: border-box;
}

html, body {
  overscroll-behavior-y: none;
  scroll-behavior: smooth;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

section {
  padding: 5% 0;
}

h3 {
  color: #000;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

p {
  color: #000;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.row {
  width: 90%;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

.line {
  width: 50px;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}

.button {
  color: #000;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #ffffffa3;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  transition: all .5s ease-in-out;
  display: inline-block;
}

.button:hover {
  color: #fff;
  background-color: #424141;
}

.button.button-white {
  color: #fff;
  background-color: #5754547d;
  border-width: 3px;
  border-color: #fff;
}

.button.button-white:hover {
  background-color: #000;
}

.button.button-margin {
  margin-left: 12px;
}

nav {
  width: 100%;
  height: 60px;
  background-color: #242323b3;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
}

.nav-logo-wht {
  width: auto;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: .75rem;
  left: 0;
  right: 85%;
}

.nav-logo-blk {
  width: 75px;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
  display: none;
  position: absolute;
  top: .75rem;
  left: 0;
  right: 85%;
}

nav ul {
  width: 60%;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

ul li {
  list-style: none;
}

.close {
  display: none;
}

nav ul li a {
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: 1px 1px 10px #010009;
  border-bottom: 1px solid #0000;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 110%;
  font-weight: 600;
  text-decoration: none;
  transition: transform .2s;
  display: block;
}

nav ul li a:hover {
  transform: scale(1.1);
}

.active a {
  text-shadow: none;
  border-bottom-color: #fff;
}

.mobile-nav-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mobile-nav-icon-blk {
  display: none;
}

.sticky {
  width: 100%;
  z-index: 9999;
  background-color: #cac6c6f2;
  animation: fadein 2s;
  position: fixed;
  top: 0;
  left: 0;
}

.sticky .nav-logo-wht {
  display: none;
}

.sticky .nav-logo-blk {
  display: inline;
}

.sticky .nav-link a {
  color: #444242;
  text-shadow: none;
}

.sticky .nav-link a:hover {
  transform: scale(1.1);
}

.sticky .active a {
  text-shadow: none;
  border-bottom-color: #444242;
}

.sticky .mobile-nav-icon {
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: #000;
  padding: 10px;
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@keyframes slidedownM {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down-M {
  animation: slidedownM 1.5s ease-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-B {
  animation: fadein 2.5s;
}

@keyframes slideupB {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.button1 {
  animation: slideupB 1.5s ease-out;
}

.header {
  color: #fff;
  background-image: linear-gradient(#00000080, #0000), url("Forest-bckgrnd.8aa652b1.jpg");
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  padding-top: 0;
}

.splash {
  width: 100%;
  opacity: 1;
  -webkit-text-fill-color: inherit;
  background-image: url("macbook90-1200.2d3790af.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-clip: border-box;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  padding: 13% 0;
  display: flex;
}

.big-text {
  width: 100%;
  color: #fff;
  text-shadow: 1px 1px 10px #010009;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 4% auto 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 120px;
  display: inline-block;
}

.medium-text {
  color: #fff;
  text-shadow: 1px 1px 10px #010009;
  margin: 4% auto 0;
  font-family: Sorts Mill Goudy, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: 400;
  display: block;
}

.button-wrapper {
  margin-top: 4%;
}

.button-wrapper a {
  font-size: 20px;
}

.begin-wrapper {
  width: 15%;
  text-align: center;
  color: #fff;
  margin: 3% auto -4%;
}

.portfolio {
  background-color: #000;
  padding-bottom: 5%;
  overflow: auto;
}

.portfolio .row {
  margin-bottom: 2%;
}

.header-2 {
  color: #fff;
}

.line-portfolio {
  border-bottom: 1px solid #fff;
}

.vid-div {
  padding: 4% 0 0;
}

.videos {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.videos div {
  color: #fff;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 200;
  display: flex;
}

.videos div:first-of-type {
  margin-right: 1%;
}

.videos div:last-of-type {
  margin-left: 1%;
}

.videos div h4 {
  margin-bottom: 2%;
}

.videos div:last-of-type h4 {
  text-align: right;
}

.portfolio ul {
  width: 100%;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.portfolio ul li {
  width: 25%;
  float: left;
  display: block;
  position: relative;
}

figure {
  width: 100%;
  background-color: #000;
  margin: 0;
  overflow: hidden;
}

figure img {
  object-fit: cover;
  width: 100%;
  height: auto;
  opacity: .7;
  transition: transform .5s, opacity .5s, -webkit-transform .5s;
  transform: scale(1.15);
}

figure img:hover {
  opacity: 1;
  transform: scale(1.03);
}

.overlay-portfolio {
  color: #fff;
  text-align: center;
  z-index: 1;
  margin: 0 auto;
  text-decoration: none;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
}

.title-portfolio {
  margin-bottom: 8%;
  font-size: 1.25rem;
}

.portfolio-sub {
  font-style: italic;
}

.about {
  background-color: gray;
  background-image: linear-gradient(#00000080, #0000), url("tiger-mtn-fixed.76cb652c.jpg");
  background-position: center;
  background-repeat: repeat, no-repeat;
  background-size: auto, contain;
  padding: 3% 0 5%;
  overflow: hidden;
}

.about .row {
  max-width: 1600px;
  position: relative;
}

.about-wrapper {
  width: 55%;
  margin: 5% 0 0 1%;
  position: absolute;
  top: 0;
  left: 0;
}

.about .row img:first-of-type {
  width: 8rem;
  margin-bottom: 1%;
}

.about .row img:last-of-type {
  display: none;
}

.about h3 {
  padding-bottom: 1.25vw;
}

.about p {
  margin-top: 4%;
  font-size: 1.5rem;
  line-height: 125%;
}

.services {
  filter: grayscale(60%);
  background-image: linear-gradient(#0000006e, #fff0), url("bronze-texture-1500.1b5bc8f5.jpg");
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
}

.title-wrapper {
  max-width: 1120px;
  background-color: #fffc;
  margin: 0 auto;
  padding: 3% 0 1.5%;
}

.phones {
  max-width: 1120px;
  background-color: #fffc;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2% 1% 3%;
  display: flex;
}

.phone-column {
  width: 100%;
  height: auto;
  background-image: url("phone-transparent.6d0f75bb.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 1%;
  padding-top: min(9vw, 120px);
  padding-bottom: 15%;
  display: inline;
}

.phones-wrapper {
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 4% 0 5%;
  display: flex;
}

.service-icon {
  max-width: 30%;
  height: auto;
  border: 1px solid #000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 6%;
  display: flex;
}

.service-icon img {
  max-width: 100%;
  height: auto;
}

.service-title {
  max-width: 100%;
  text-align: center;
  margin-top: 8%;
  font-size: clamp(.5rem, 3vw, 2rem);
}

.service-name {
  max-width: 100%;
  text-align: center;
  margin-top: 6%;
  font-size: clamp(.75rem, 1.7vw, 1.5rem);
  font-style: italic;
}

.phone-column p {
  width: 65%;
  height: auto;
  text-align: center;
  margin: 5% 17.5% 0;
  font-size: clamp(.75rem, 1.7vw, 1.5rem);
  line-height: 150%;
}

.features {
  background-color: #000;
  padding-bottom: 0;
}

.features .row:last-of-type {
  margin-bottom: 0;
  display: flex;
}

.features-header {
  color: #c7c7c7;
  text-align: center;
}

.features-line {
  border-bottom: 1px solid #c7c7c7;
}

.mtn-logo {
  float: left;
  width: 49%;
  flex-direction: column;
  align-items: center;
  margin: 100px 65px 300px 0;
  display: flex;
}

.mtn-logo img {
  width: 70%;
  height: auto;
}

.mtn-logo text {
  color: #c7c7c7;
  margin-top: 5%;
  font-size: clamp(1rem, 2.25vw, 2rem);
  font-weight: 100;
}

.features .row {
  margin-bottom: 3%;
}

.features ul {
  width: 49%;
  flex-direction: column;
  list-style: none;
  display: flex;
}

.list {
  margin-bottom: 3%;
  display: flex;
}

.icon-features {
  float: left;
  width: 92px;
  height: 92px;
  border: 1px solid #c7c7c7;
  border-radius: 100%;
  margin: 0 5% 0 0;
  padding: 20px 20px 15px;
}

.icon-features img {
  width: 50px;
}

.features-title {
  text-align: left;
  color: #c7c7c7;
  margin-bottom: 3%;
  font-size: 150%;
  font-weight: 400;
}

.features-text p {
  color: #c7c7c7;
  text-align: left;
  margin-bottom: 5%;
  font-size: 120%;
}

.contact-row {
  margin-bottom: 3%;
}

form {
  width: 70%;
  flex-direction: column;
  margin: 0 auto 4%;
  display: flex;
}

.contact-section {
  background-color: #fafafa;
}

.text-field {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Sorts Mill Goudy, sans-serif;
  font-size: 15px;
  font-style: italic;
}

.text-field.text-area {
  resize: vertical;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 90px;
}

.submit-btn {
  width: 100%;
  justify-content: center;
  display: flex;
}

.submit-btn input {
  margin: 0 auto;
}

.row-email {
  flex-direction: column;
  font-size: 2vw;
  line-height: 150%;
  display: flex;
}

.mailto {
  color: #000;
  background-color: #ffffffa3;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  margin: 3% auto 0;
  padding: 15px 10px;
  font-size: 2.5rem;
  text-decoration: none;
}

.mailto:hover {
  color: #fff;
  background-color: #c7c7c7;
  transition: all .5s ease-in-out;
}

footer {
  background-color: gray;
  padding: 3% 0;
}

.go-to-top {
  color: #fff;
  font-size: 150%;
  text-decoration: none;
}

.go-to-top:hover {
  color: #c7c7c7;
  transition: all .5s ease-in-out;
}

.social-icons-footer {
  margin: 2% 0;
}

.social-icons-footer a {
  margin: 0 1%;
}

.social-icons-footer a:nth-child(2) {
  display: none;
}

.copyright {
  font-size: 125%;
}

@media screen and (max-width: 1400px) {
  .splash {
    background-size: auto 95%;
  }

  .about-wrapper {
    margin-top: 2%;
  }
}

@media screen and (max-width: 1300px) {
  .about-wrapper {
    margin-top: 0;
  }

  iframe {
    width: 500px;
    height: 281px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-link {
    font-size: 1.25vw;
  }

  .splash {
    background-size: auto 90%;
  }

  .big-text {
    font-size: clamp(1.5rem, 4vw, 4rem);
    line-height: 7vw;
  }

  .medium-text {
    margin-top: 5.5%;
    font-size: 1.75vw;
  }

  .button-wrapper .button {
    border: .5px solid #c7c7c7;
    padding: 1vw 1.5vw;
  }

  .button-wrapper a {
    font-size: 1.25vw;
  }

  .begin-wrapper {
    margin-top: 1%;
  }

  iframe {
    width: 42vw;
    height: 23.52vw;
  }

  .about p {
    font-size: 1.25rem;
  }

  .mtn-logo {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .about-wrapper {
    width: 60%;
    margin-left: 0;
    left: -2%;
  }

  .videos h4 {
    font-size: 1rem;
  }

  .about .row img:first-of-type {
    width: 6rem;
  }
}

@media screen and (max-width: 1000px) {
  .videos div:first-of-type {
    margin-right: .5%;
  }

  .videos div:last-of-type {
    margin-left: .5%;
  }

  .about-wrapper {
    width: 55%;
    margin-top: 2%;
    margin-left: 0;
    left: 1%;
  }

  .about .row img:first-of-type {
    display: none;
  }

  .mtn-logo {
    margin-top: 150px;
  }

  .icon-features {
    margin-bottom: 30%;
  }

  .features-text {
    margin-bottom: 10%;
  }

  .about-wrapper {
    width: 60%;
    margin-top: 0;
    left: -1%;
  }

  .about p {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 900px) {
  .nav {
    width: 70%;
  }

  .videos {
    flex-direction: column;
    align-items: center;
  }

  .videos div {
    width: 560px;
  }

  .videos div h4 {
    margin: 5% 0 2%;
  }

  .videos div:last-of-type h4 {
    text-align: left;
  }

  iframe {
    width: 100%;
    height: 315px;
  }
}

@media screen and (max-width: 800px) {
  * {
    animation: none !important;
  }

  .header {
    background-image: linear-gradient(#00000080, #0000), url("Forest-bckgrnd800.3b83cc4d.jpg");
  }

  .row {
    width: 95%;
  }

  .nav-link {
    font-size: 1.5vw;
  }

  .nav {
    width: 80%;
  }

  .nav-logo-wht, .sticky .nav-logo-blk {
    display: none;
  }

  .title-portfolio {
    font-size: 1rem;
  }

  .splash {
    background-image: url("macbook90-800.3dba5b26.png");
    background-size: auto 105%;
    margin-top: 2%;
  }

  .big-text {
    font-size: 5vw;
    line-height: 8vw;
  }

  .splash .medium-text {
    margin-top: 2%;
    font-size: 2.4vw;
  }

  .portfolio ul li {
    width: 30%;
  }

  .videos {
    margin-bottom: 3%;
  }

  .videos div {
    width: 70vw;
  }

  iframe {
    height: 39.2vw;
  }

  .about-wrapper {
    width: 55%;
    left: 2%;
  }

  .about p {
    font-size: 1rem;
  }

  .services {
    background-image: linear-gradient(#0000006e, #fff0), url("bronze-texture-800.116ab185.jpg");
  }

  .features .row {
    flex-direction: column;
    display: flex;
  }

  .mtn-logo {
    display: none;
  }

  .features ul {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .list {
    width: 30%;
    flex-direction: column;
    align-items: center;
  }

  .icon-features {
    width: 60px;
    height: 60px;
    margin: 0 0 10%;
    padding: 12px;
  }

  .icon-features img {
    width: 35px;
  }

  .features-title {
    text-align: center;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 8%;
    font-size: 2.5vw;
    font-style: italic;
  }
}

@media screen and (max-width: 700px) {
  .splash {
    background-size: auto 100%;
  }

  .big-text {
    font-size: 5vw;
    line-height: 8vw;
  }

  .splash .medium-text {
    margin-top: 3%;
    font-size: 2.2vw;
  }

  .button-wrapper a {
    font-size: 1.5vw;
  }

  .portfolio ul li {
    width: 33.3%;
  }

  .about {
    width: 100%;
    background-image: linear-gradient(#00000080, #0000), url("<700-about-background.c3f165c9.jpg");
    background-size: auto, cover;
    background-attachment: fixed;
  }

  .about-wrapper {
    width: 80%;
    top: auto;
    left: unset;
    margin: 0 auto;
    position: relative;
  }

  .about .row img:last-of-type {
    width: 25%;
    margin: 5% auto 3%;
    display: block;
  }

  .about h3, .about p {
    color: #c7c7c7;
  }

  .about p {
    font-size: 1.5rem;
    line-height: 135%;
  }

  .about .line {
    border-bottom: 1px solid #c7c7c7;
  }

  .features ul {
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
  }

  .list {
    width: 80%;
  }

  .features-title {
    border: none;
    margin-bottom: 3%;
    font-size: 5vw;
    font-style: normal;
  }

  .about img {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .nav {
    width: 90%;
  }

  .begin-wrapper {
    width: 40%;
  }

  .nav-link {
    font-size: 1.6vw;
  }

  .button-wrapper a {
    font-size: 1.5vw;
  }

  .title-portfolio {
    font-size: 1rem;
  }

  .videos div {
    width: 80vw;
  }

  iframe {
    height: 44.8vw;
  }

  .phones {
    flex-direction: column;
    padding: 0 1.5% 0 0;
  }

  .phone-column {
    background-image: url("phone-landscape.fcddc522.png");
    padding: 7% 0 10%;
  }

  .phones-wrapper {
    padding: 0 5%;
  }

  .service-icon {
    width: 40px;
    border: none;
    padding: 0;
  }

  .service-title {
    margin-top: 2%;
  }

  .service-name {
    margin-top: 1%;
    font-size: 3vw;
  }

  .phone-column p {
    margin-top: 2%;
    font-size: 3vw;
  }
}

@media screen and (max-width: 500px) {
  body {
    overflow-anchor: none;
  }

  nav {
    background-color: #0000;
    border-bottom: none;
    flex-direction: column;
    position: relative;
  }

  nav ul {
    float: left;
    z-index: 9;
    max-width: 160px;
    height: 250px;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    padding: 3% 5% 2%;
    display: none;
    position: absolute;
  }

  .close {
    width: 30px;
    height: 30px;
    z-index: 9;
    display: none;
    position: absolute;
    bottom: -195px;
    left: 135px;
  }

  nav ul li {
    margin: 3% 0;
  }

  nav ul li a {
    color: #000;
    text-shadow: none;
    font-size: 14px;
  }

  .mobile-nav-icon {
    display: block;
  }

  .sticky {
    background-color: #0000;
  }

  .sticky .mobile-nav-icon {
    display: block;
  }

  .splash {
    background-size: auto 90%;
    padding: 10% 0 13%;
  }

  .big-text {
    font-size: 7vw;
    line-height: 10vw;
  }

  .splash .medium-text {
    margin-top: 3%;
    font-size: 2vw;
  }

  .button-wrapper {
    margin-top: 1%;
  }

  .line-portfolio {
    margin-bottom: 5%;
  }

  .about .row img:last-of-type {
    width: 30%;
    margin: 5% auto;
  }

  .phone-column {
    background-size: auto cover;
    background-position: 0 0, 50%;
    position: relative;
  }

  .service-icon {
    max-width: 8%;
  }

  .service-title {
    letter-spacing: .25rem;
    font-weight: 600;
  }

  .phone-column p {
    width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
  }

  form {
    width: 90%;
  }

  .social-icons-footer {
    margin-top: 5%;
  }
}

@media screen and (max-width: 450px) {
  .portfolio ul {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .portfolio ul li {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .header {
    background-image: linear-gradient(#00000080, #0000), url("Forest-bckgrnd400.dbe6bfe1.jpg");
  }

  .splash {
    width: 100%;
    background-image: url("macbook90-400.ad0485c0.png");
    background-size: auto 100%;
    padding: 11% 0 13%;
  }

  .big-text {
    margin-top: 15%;
    margin-bottom: 15%;
    font-size: 8vw;
  }

  .medium-text, .button-wrapper {
    display: none;
  }

  .portfolio ul {
    width: 100%;
  }

  .portfolio ul li {
    width: 70%;
  }

  .videos div {
    width: 100vw;
  }

  .videos div h4 {
    margin-left: 2%;
    font-size: .75rem;
  }

  iframe {
    height: 56vw;
  }

  .services {
    background-image: linear-gradient(#0000006e, #fff0), url("bronze-texture-400.5d4b6cbe.jpg");
  }

  .features-title {
    margin-bottom: 5%;
    font-size: 6vw;
  }

  .row-email {
    font-size: 4vw;
  }

  .mailto {
    width: 60%;
    font-size: 5vw;
  }

  footer .row {
    margin-top: 5%;
  }

  .social-icons-footer {
    margin-top: 8%;
  }
}

@media screen and (max-width: 300px) {
  .splash {
    background-size: auto 90%;
  }

  .big-text {
    margin-top: 16%;
    font-size: 7vw;
  }

  .portfolio ul li {
    width: 90%;
  }

  .about p {
    font-size: 1.25rem;
  }

  .services h3 {
    color: #c7c7c7;
  }

  .services .line {
    border-bottom: 1px solid #c7c7c7;
  }

  .title-wrapper, .phones {
    background-color: #0000;
  }

  .phone-column {
    background-color: #fffc;
    background-image: none;
    margin-top: 5%;
  }

  .phones-wrapper {
    padding: 0 4%;
  }

  .service-icon {
    max-width: 40%;
  }

  .service-title {
    font-size: 5vw;
  }

  .service-name {
    font-size: 5vw;
    font-weight: 400;
  }

  .phone-column p {
    font-size: 5vw;
    font-weight: 200;
  }

  form {
    width: 95%;
  }
}

/*# sourceMappingURL=index.4a19c3f5.css.map */
